import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";


import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroPage from '../components/services/hero';
import Customers from '../components/customers/customers';

const IndexPage = ({
  pageContext: {
    customerPage
  }
}) => {
  const homePageSeoTitle = "C&R Asphalt Animals |  Lexington's Top Rated Paving Company | Serving Lexington, Louisville, Georgetown, Versailles, Frankfort, Winchester";
  return (
    <Layout>
      <SEO title={homePageSeoTitle} seo={customerPage.seo?.[0]} />
      <HeroPage
        title={customerPage.title}
        subtitle={customerPage.subtitle}
        cta={'Start a project'}
        image={customerPage.mainImage} />
      <Customers customers={customerPage.customers} />
    </Layout>
  );
};

const mapStateToProps = state => {
  return {  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
    }, dispatch),
  }
};

const Index = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexPage);

export default Index;
