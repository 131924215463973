import React from 'react';
import {
  Typography,
  Divider,
  Paper,
  Accordion, AccordionSummary, AccordionDetails,
  Button,
  Tabs, Tab, useMediaQuery
} from '@material-ui/core';

import Image from 'gatsby-image';
import {
  ExpandMore
} from '@material-ui/icons';
import {
  makeStyles, ThemeProvider
} from '@material-ui/styles';
import PortableText from '../content/portableText';
import '../about/teamMembers/portableTextStyles.css';
import theme from '../../themes/lightTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
    // maxWidth: '100vw'
  },
  accordion: {
    width: '100%',
    // maxWidth: 1100,
    background: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw'
    },
    marginBottom: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold' as 'bold',
    margin: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2),
    marginTop: 0
  },
  image: {
    height: 400
  },
  customers: {
    // display: 'grid',
    // gridTemplateColumns: 'auto auto auto',
    columns: 3,
    columnFill: 'balance',
    justifyContent: 'center',
    padding: theme.spacing()
  },
  customer: {
    // background: theme.palette.background.paper,
    padding: theme.spacing(),
    margin: theme.spacing(0.5)
  },
  paperWrapper: {
    margin: theme.spacing(),
  },
  nav: {
    // display: 'flex',
    // overflow: 'auto',
    // // marginBottom: theme.spacing(2)
  },
  tab: {
    // margin: theme.spacing()
    textTransform: 'none'
  },
  tabs: {
    width: '100%',
    // maxWidth: 1100,
    maxWidth: '100vw',
  }
}))

export default ({customers}) => {
  const classes = useStyles();
  const [selectedSegment, setSelectedSegment] = React.useState(0);
  const handleTabChange = (e, n) => setSelectedSegment(n);
  const screenIsLarge = useMediaQuery(theme.breakpoints.up('lg'));
  return <div className={classes.root}>
    <div className={classes.tabs}><Tabs centered={screenIsLarge} scrollButtons="auto" variant={screenIsLarge ? "standard" : "scrollable"} onChange={handleTabChange} indicatorColor="primary" value={selectedSegment} className={classes.nav}>
      {customers.map((types) =>
        types.customerSegments.map((segment, idx) =>
          <Tab
            key={segment}
            label={segment.title}
            className={classes.tab}
            key={segment} />
          ))}
    </Tabs></div>
    <Divider />


    <Paper square className={classes.accordion}>
      <Typography className={classes.title} align='center' variant='h6'>{customers[0].customerSegments[selectedSegment].title}</Typography>
      <Divider className={classes.divider} />
      <Image className={classes.image} fluid={customers[0].customerSegments[selectedSegment].mainImage.assetLg.fluid} />
        <div className={classes.customers}>
          {customers[0].customerSegments[selectedSegment].customer.map(c =>
              <Typography>{c.title}</Typography>
          )}
          </div>
    </Paper>
  </div>;
}
